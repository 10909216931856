<template>
  <div class="g_page_box" style="background: #f2f7f8;width:100%; overflow-x:hidden;">
    <g-loading :loading="loading" />
    <div class="g_main_content order">
      <div class="order-i" v-if="data" >
        <div class="head-img">
          <img
            v-if="data.avatar_url"
            width="100%"
            height="100%"
            :src="data.avatar_url"
          />
          <img
            v-else
            width="100%"
            height="100%"
            src="../../assets/img/online/doctor.png"
          />
        </div>
        <div class="g_main_content_img">
          <img src="../../assets/img/online-chat/img_circle@2x.png" />
        </div>
        <div class="i-heard">
          <div class="heard-name">
            <div>{{ data.online_doctor_name }}</div>
            <span
              ><span style="padding-right: 5px">{{
                data.doctor_title_label
              }}</span
              >{{ data.department }}</span
            >
          </div>
          <!-- <div :class="['level',{'level2': data.doctor_role_code === 2}]">{{data.doctor_role_code === 1 ? '可开药' : '仅咨询'}}</div> -->
        </div>
        <div class="i-content">
          <div>
            好评率
            <van-rate
              readonly
              gutter="2px"
              :size="10"
              v-model="data.score"
              void-icon="star"
              class="star"
              color="#ffd21e"
            />
          </div>
          <div>
            接诊人次数 <span> {{ data.inquiry_count }} </span>
          </div>
          <div>
            平均响应 <span> {{ hander_time(data.avg_response_seconds) }} </span>
          </div>
        </div>
        <div class="desc">{{ data.desc }}</div>
      </div>
      <div class="serve">
        <div class="title">服务流程</div>
        <div class="text">
          支付完成后，医生将在24小时内为您提供在线问诊服务
          问诊完成后医生开取处方，支持送药上门(部分疾病需复查各项指标后，才能开处方)
        </div>
        <ul class="process">
          <li>
            <div class="name"><span>1.</span>付费</div>
            <div class="icon" />
          </li>
          <li>
            <div class="name"><span>2.</span>问诊</div>

            <div class="icon" />
          </li>
          <li>
            <div class="name"><span>3.</span>检查</div>
            <div class="icon" />
          </li>
          <li class="four">
            <div class="name"><span>4.</span>开处方</div>
            <div class="icon" />
          </li>
        </ul>
      </div>
      <div class="comment">
        <div class="title">
          <span>患者评价({{ total }})</span>
          <div @click="look_comment" v-if="list.length > 0">全部评价</div>
        </div>
        <div
          class="no-data"
          v-if="list.length === 0 && !loading"
          style="margin-bottom: 40px"
        >
          <img width="185" src="../../assets/img/order/no-date.png" />
          暂无数据
        </div>
        <div v-for="(i, index) in list" :key="index + 'comment'" v-else>
          <div class="header">
            <div class="img">
              <img
                v-if="i.avatar_url"
                height="100%"
                width="100%"
                :src="i.avatar_url"
              />
              <img
                v-else
                height="100%"
                width="100%"
                src="../../assets/img/online/doctor_default.png"
              />
            </div>
            <span>{{ i.nickname.substr(0, 1) + "**" }}</span>
            <van-rate
              readonly
              :size="12"
              v-model="i.score"
              void-icon="star"
              class="star"
              color="#ffd21e"
            />
          </div>
          <div class="comment-content">
            {{ i.content }}
          </div>
          <div class="date_str">{{ i.date_str }}</div>
        </div>
      </div>
    </div>
    <div>
      <div
        :class="['footer', 'g-default-height', { 'g-ios-height': is_ios }]"
        v-if="!$route.query.hide_pay"
      >
        <div class="money">
          <span>¥</span>{{ data && format_money(data.inquiry_price) }}
          <div class="text">总金额</div>
        </div>
        <div v-if="state === 1" class="bt" @click="to_chat">立即沟通</div>
        <div v-else class="bt" @click="to_pay">
          立即支付 (¥{{ data && format_money(data.inquiry_price) }}/24小时）
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { get_detail, get_comment } from "./service";
import { format_money } from "../../utils/format";
import { mapState } from "vuex";
import {get_conversation_state} from '../../im/service'
export default {
  data() {
    return {
      data: null,
      loading: false,
      comment_loading: false,
      list: [],
      total: 0,
      state: null,
    };
  },
  components: {},
  computed: {
    ...mapState("commonData", {
      is_ios: (state) => state.is_ios,
    }),
  },
  watch: {},
  created() {
    this.search_detail();
    this.search_comment();
  },
  methods: {
    format_money,

    hander_time(time) {
      if (time < 60) {
        return 1 + "分钟内";
      } else if (time < 3600) {
        return Math.floor(time / 60) + "分钟内";
      } else {
        return Math.floor(time / 3600) + "小时内";
      }
    },
    look_comment() {
      this.$router.push({
        path: "/online-comments",
        query: {
          id: this.$route.query.id,
        },
      });
    },
    to_pay() {
      this.$router.push({
        path: "/online-pay",
        query: {
          id: this.$route.query.id,
        },
      });
    },
    to_chat() {
      this.$router.push({
        path: "/online-chat",
        query: {
          id: this.data.sdk_user_id,
        },
      });
    },
    async search_detail() {
      const { id } = this.$route.query;
      try {
        this.loading = true;
        const { data } = await get_detail(id);
        data.score = Math.ceil(data.score);
        this.data = data;
        this.search_state(data.sdk_user_id)
      } finally {
        this.loading = false;
      }
    },
    async search_comment() {
      const { id } = this.$route.query;
      try {
        this.comment_loading = true;
        const { data } = await get_comment({
          id,
          params: { page: 0, page_size: 10 },
        });
        for (let i of data.data) {
          i.score = Math.ceil(i.score);
        }
        this.list = data.data;
        this.total = data.total_elements;
      } finally {
        this.comment_loading = false;
      }
    },
    async search_state(id) {
      const { data } = await get_conversation_state({sdk_users: [id]})
      this.state = data[id].state;
    },
  },
};
</script>
<style scoped>
.order {
  padding: 60px 10px;
}
.g_main_content_img {
  width: 120px;
  position: absolute;
  top: -60px;
  right: 10px;
}
.g_main_content_img img {
  width: 100%;
  opacity: 0.4;
}
.order-i {
  margin-bottom: 16px;
  background: #ffffff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
  padding: 15px 8px 27px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  position: relative;

  & .head-img {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    right: 40px;
    top: -34px;
    z-index: 999;
    object-fit: cover;
    border: 4px #fff solid;
    background: rgba(0, 0, 0, 0.1);
    & img{
      object-fit: cover;
    }
  }
  & .i-heard {
    display: flex;
    align-items: center;
    & .heard-name {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      color: #242938;
      & > span {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #898995;
      }
    }

    & .level {
      width: 58px;
      height: 23px;
      margin-left: 20px;
      background: #44bb00;
      border-radius: 2px;
      font-size: 12px;
      color: #ffffff;
      text-align: center;
      line-height: 23px;
      &.level2 {
        background: rgba(0, 136, 255, 1);
      }
    }
  }
  & .i-content {
    font-size: 12px;
    color: rgba(153, 153, 153, 1);
    padding: 5px 0 0;
    line-height: 18px;
    display: flex;
    flex-wrap: wrap;
    & > div {
      display: flex;
      align-items: center;
      & > span {
        padding: 0 6px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 124, 233, 1);
      }
      & .star {
        padding: 0 6px 3px;
      }
    }
  }
  & .desc {
    padding-top: 17px;
    font-size: 14px;
    line-height: 18px;
    color: #323233;
  }
}
.serve {
  margin-bottom: 16px;
  background: #ffffff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
  padding: 15px 8px;
  border-radius: 10px;
  & .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    color: #242938;
    padding-bottom: 10px;
  }
  & .text {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #999999;
    padding-bottom: 20px;
  }
}
.process {
  display: flex;
  border-left: 1px solid #f4f1e1;
  font-size: 12px;
  & li {
    flex: 1;
    position: relative;
    height: 32px;
    background: #fefced;
    border-right: none;
    border-left: none;
    display: flex;
    align-items: center;

    & .name {
      height: 100%;
      background: #fefced;
      border-top: 1px solid #f4f1e1;
      border-bottom: 1px solid #f4f1e1;
      display: flex;
      align-items: center;
      padding-left: 10px;
      flex: 1;
      & > span {
        color: rgba(153, 153, 153, 1);
      }
    }
    & .icon {
      position: absolute;
      right: 0;
      width: 0;
      height: 0;
      border-top: 16px solid transparent;
      border-left: 16px solid #f4f1e1;
      border-bottom: 16px solid transparent;
      &:after {
        content: "";
        position: absolute;
        top: -14px;
        left: -16px;
        border-top: 14px solid transparent;
        border-left: 14px solid #fefced;
        border-bottom: 14px solid transparent;
      }
    }
    &.four {
      background: #fff;
      & .icon {
        position: relative;
      }
    }
  }
}
.comment {
  margin-bottom: 16px;
  background: #ffffff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
  padding: 15px 8px;
  border-radius: 10px;
  & .title {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 38px;
    color: #0088ff;
    align-items: center;
    & > span {
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      color: #242938;
      flex: 1;
    }
  }
  & .header {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    color: #323233;
    padding: 14px 0 7px;
    & .img {
      width: 20px;
      height: 20px;
      overflow: hidden;
      border-radius: 50%;
      pointer-events: none;
      margin-right: 10px;
    }
    & > span {
      flex: 1;
    }
  }
  & .comment-content {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #323233;
    padding-bottom: 6px;
    padding-left: 30px;
  }
  & .date_str {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #999999;
    padding-left: 30px;
  }
}
.footer {
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  position: relative;
  position: relative;
  &::after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    border: 1 solid #ebedf0;
    transform: scale(0.5);
  }
  &::after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    border: 1 solid #ebedf0;
    transform: scale(0.5);
  }
  & .money {
    color: #ff6600;
    font-size: 22px;
    font-weight: 600;
    line-height: 20px;
    color: #ff6600;
    padding-right: 20px;
    padding-top: 1px;

    & > span {
      font-size: 12px;
      font-weight: 400;
      padding-right: 3px;
    }
    & > div {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      color: #707070;
    }
  }
  & .bt {
    height: 36px;
    background: #1890ff;
    border-radius: 18px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
  }
}
.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  & img {
    margin-bottom: 28px;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    color: rgba(112, 112, 112, 0.5);
  }
}
</style>
