import api from '../../utils/api'
// 登录
export async function get_detail (payload) {
  console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         online_doctor_id: 1,
  //         online_doctor_name: '医生一',
  //         department: '科室一',
  //         doctor_title: '主任医师',
  //         doctor_role: '医生',
  //         doctor_role_code: 1,
  //         score: 1.5,
  //         inquiry_count: 20000,
  //         avg_response_seconds: 200,
  //         order_id: 1,
  //         medical_card_no: 123,
  //         desc: '妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌',
  //         inquiry_price: 200,
  //         Introduction: '妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌'
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/inquiries/doctors/${payload}`)
}
export async function get_comment (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         total_elements: 7,
  //         data: [
  //           {
  //             online_doctor_id: 1,
  //             patient_name:'张三',
  //             patient_id: 1,
  //             content: '遇见医生之前差点放弃一条小生命，也是家长对疾病的无知！',
  //             score: 5,
  //             date_str: '两周前'
  //           },
  //           {
  //             online_doctor_id: 1,
  //             patient_name:'张三',
  //             patient_id: 2,
  //             content: '遇见医生之前差点放弃一条小生命，也是家长对疾病的无知！',
  //             score: 5,
  //             date_str: '两周前'
  //           },
  //           {
  //             online_doctor_id: 1,
  //             patient_name:'张三',
  //             patient_id: 3,
  //             content: '遇见医生之前差点放弃一条小生命，也是家长对疾病的无知！',
  //             score: 5,
  //             date_str: '两周前'
  //           }
  //         ]
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/inquiries/doctors/${payload.id}/comments`,{params: payload.params})
}



